<template>
  <div>
    <b-button-group class="mb-1" v-if="isRoled()">
      <router-link
        to="/lessons/0"
        custom
        v-slot="{ href, navigate, isActive }"
      >
        <a :href="href" @click="navigate">
            <b-button
              style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
              :variant="isActive ? 'primary' : 'outline-primary'"
            >
            دروس الوحدات
            </b-button>
        </a>
      </router-link>
      <router-link
        to="/lessons/1"
        custom
        v-slot="{ href, navigate, isActive }"
      >
        <a :href="href" @click="navigate">
            <b-button
              style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
              :variant="isActive ? 'primary' : 'outline-primary'"
            >
              دروس الجلسات
            </b-button>
        </a>
      </router-link>
    </b-button-group>
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BButton,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";

export default {
  components: {
    BButtonGroup,
    BButton,
  },
  props : {
      index : String
  },
  setup() {
    const { routerTransition } = useAppConfig()

    return {
      routerTransition
    }
  },
  created(){
  },
  methods: {
      isRoled(){
          return getUserData().role == Admin
      },
  },
  watch: {
      index(val) {
          console.log(val)
      }
  }
}
</script>
